<template>
  <div class="page">
    <div class="page-title">大数据研判</div>
    <div class="page-header">
      <div class="page-header-sel">
        <el-select
          v-model="headerSelVal"
          @change="headerSelValChange"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="page-header-btn-list">
        <div class="page-header-btn1 header-btn" @click="getWaitForJudgmentData">
          <div>
            <img src="@/assets/img/zhang/huoqushuju.png" alt="" />
            <span class="big-span">获取数据</span>
            <br />
            <span class="small-span">(待处理: {{ waitForJudgmentNum }}条)</span>
          </div>
        </div>
        <div class="page-header-btn2 header-btn" @click="submitExceptionFilter">
          <div>
            <img src="@/assets/img/zhang/tijiaoshuju.png" alt="" />
            <span>提交数据</span>
          </div>
        </div>
      </div>
      <div class="page-header-btn-back" @click="signOutDialogVisible = true">
        <img src="@/assets/img/zhang/tuichuyanpan.png" alt="" />
        <span>退出研判</span>
      </div>
    </div>
    <el-card shadow="never" class="big-data-info" v-loading="waitForJudgmentDataLoading">
      <div class="big-data-info-box" v-for="(item, index) in bigDataInfoList" :key="index">
        <div
          class="info-box-header"
          :class="item.messageTypeList.length > 0 ? 'info-box-header-red' : ''"
        >
          <el-select
            class="box-header-sel"
            v-model="item.messageTypeList"
            placeholder="请选择异常状态类型"
            multiple
            collapse-tags
            filterable
            style="width: 18.75rem"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="item in harOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value / 1"
            ></el-option>
          </el-select>
          <div class="box-header-btn" @click="emptyAllHarmfulType(index)">
            正常信息
          </div>
          <div
            class="box-header-h3"
            :class="item.messageTypeList.length > 0 ? 'box-header-h3-y' : ''"
          >
            {{ item.accountName || "无" }}
          </div>
          <div class="box-header-copy" @click="jumpUrl(item.webHomeUrl)">
            <img
              v-if="item.messageTypeList.length == 0"
              src="@/assets/img/zhang/chakanyuandizhi.png"
              alt=""
            />
            <img v-else src="@/assets/img/zhang/chankanyuandizhi1.png" alt="" />
            <span
              style="color: #ed731f"
              :class="item.messageTypeList.length > 0 ? 'info-box-copy-color' : ''"
            >
              查看源地址
            </span>
          </div>
        </div>
        <div
          class="info-box-main"
          :class="item.messageTypeList.length > 0 ? 'info-box-main-pink' : ''"
        >
          <div class="box-main-author">
            <div class="main-author-1 main-author-author">
              状态类型：
              <span style="color: #02bc7c" v-if="item.messageTypeList.length == 0">正常</span>
              <span style="color: #e93343" v-else>异常</span>
            </div>
            <div class="main-author-2 main-author-author">
              所属区域：{{ item.areaName || "无" }}
            </div>
            <div class="main-author-3 main-author-author">
              主体类型：{{ item.bodyTypeName || "无" }}
            </div>
            <div class="main-author-4 main-author-author">
              主办单位：{{ item.companyName || "无" }}
            </div>
            <div class="main-author-5 main-author-author">
              ICP备案：{{ item.icpNumber || "无" }}
            </div>
          </div>
        </div>
        <div
          class="info-box-checkbox"
          :class="item.messageTypeList.length > 0 ? 'info-box-main-pink' : ''"
        >
          <div class="info-box-checkbox-left">
            <div class="checkbox-left1">关联机构</div>
            <div class="checkbox-left2">
              <el-checkbox
                style="margin-right: 0rem"
                v-model="item.isCheckedAll"
                @change="
                  checkAll(item);
                  $forceUpdate();
                "
              >
                全选
              </el-checkbox>
            </div>
          </div>
          <div class="info-box-checkbox-center" @click="addOrganization(item)">
            <img src="@/assets/img/zhang/dajiahao.png" alt="" />
          </div>
          <div class="info-box-checkbox-right">
            <div class="checkbox-right1">
              <div class="checkbox-right1-left checkbox-right-left">
                <el-checkbox
                  class="platform-check-all"
                  v-model="item.isNetmailCheckedAll"
                  @change="
                    letterCheckAll(item);
                    $forceUpdate();
                  "
                >
                  网信
                </el-checkbox>
              </div>
              <div class="checkbox-right1-right checkbox-right-right">
                <el-checkbox
                  class="platform-check-more"
                  v-for="(items, indexs) in item.netmailList"
                  :key="indexs"
                  :label="items"
                  v-model="items.isChecked"
                  @change="
                    letterChecked(item);
                    $forceUpdate();
                  "
                >
                  {{ items.name }}
                </el-checkbox>
              </div>
            </div>
            <div class="checkbox-right2">
              <div class="checkbox-right2-left checkbox-right-left">
                <el-checkbox
                  class="platform-check-all"
                  v-model="item.isNetworkSecurityCheckedAll"
                  @change="
                    altarCheckAll(item);
                    $forceUpdate();
                  "
                >
                  网安
                </el-checkbox>
              </div>
              <div class="checkbox-right2-right checkbox-right-right">
                <el-checkbox
                  class="platform-check-more"
                  v-for="(items, indexs) in item.networkSecurityList"
                  :key="indexs"
                  :label="items"
                  v-model="items.isChecked"
                  @change="
                    altarChecked(item);
                    $forceUpdate();
                  "
                >
                  {{ items.name }}
                </el-checkbox>
              </div>
            </div>
            <div class="checkbox-right3">
              <div class="checkbox-right3-left checkbox-right-left">
                <el-checkbox
                  class="platform-check-all"
                  v-model="item.isOtherCheckedAll"
                  @change="
                    otherCheckAll(item);
                    $forceUpdate();
                  "
                >
                  其他
                </el-checkbox>
              </div>
              <div class="checkbox-right3-right checkbox-right-right">
                <el-checkbox
                  class="platform-check-more"
                  v-for="(items, indexs) in item.otherList"
                  :key="indexs"
                  :label="items"
                  v-model="items.isChecked"
                  @change="
                    otherChecked(item);
                    $forceUpdate();
                  "
                >
                  {{ items.name }}
                </el-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="noDataDis" v-if="bigDataInfoList.length == 0">
        点击获取数据
      </div>
    </el-card>
    <!-- 退出研判 -->
    <el-dialog
      title="退出研判"
      :visible.sync="signOutDialogVisible"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <h3 class="delete_h3">请确认退出研判！</h3>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancal_z" @click="signOutDialogVisible = false">取 消</el-button>
        <el-button class="search_z" type="primary" @click="quitPendingYes">确定</el-button>
      </div>
    </el-dialog>
    <!-- 添加机构弹窗 -->
    <el-dialog
      title="添加机构"
      :visible.sync="addOrgdialogVisible"
      width="700px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="add_organization">
        <el-select
          style="width: 31.25rem; margin-right: 1.5rem"
          v-model="addOrgListSelect"
          multiple
          filterable
          remote
          reserve-keyword
          placeholder="模糊搜索机构名称"
          :remote-method="remoteMethod"
          value-key="id"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="(item, index) in addOrgList"
            :key="index"
            :label="item.name"
            :value="item"
          ></el-option>
        </el-select>
        <el-button class="search_z" type="primary" @click="serchOrganization">
          添加
        </el-button>
        <div class="tagsRegion">
          <div v-for="(val, index) in tagsRegion" :key="index" class="tagsRegion-card">
            <span>
              {{ val.name }}
            </span>
            <img
              src="@/assets/img/lvchaofeng/bigdata/chahao.png"
              @click="removeOrganization(index)"
            />
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="cancal_z" @click="addOrgdialogVisible = false">取 消</el-button>
        <el-button class="search_z" type="primary" @click="addOrganizationYes">添 加</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bigdata from "@/api/bigdata/index";
import Error from "@/api/error/index";
export default {
  data() {
    return {
      // 详情数据
      detailsData: {},
      // 添加机构tags
      tagsRegion: [],
      // 弹窗机构选择数据
      addOrgListSelect: [],
      // 弹窗机构数据
      addOrgList: [],
      // 添加机构弹窗
      addOrgdialogVisible: false,
      // 退出研判弹窗
      signOutDialogVisible: false,
      // 待研判数据数量
      waitForJudgmentNum: 0,
      // 提交数据的批次id
      batchId: "",
      // 加载效果
      waitForJudgmentDataLoading: false,
      headerSelVal: 2, //有害信息val
      options: [
        { value: 0, label: "有害信息" },
        { value: 1, label: "错误表述" },
        { value: 2, label: "异常状态" },
      ],
      harOptions: [],
      // 列表数据
      bigDataInfoList: [],
    };
  },
  created() {
    this.getAbnormalStateNum();
    this.getErrorTypeList();
  },
  mounted() {},
  methods: {
    // 切换数据列表
    headerSelValChange() {
      if (this.headerSelVal == 0) {
        this.$router.push("/bigdata/harmfulInformation");
      }
      if (this.headerSelVal == 1) {
        this.$router.push("/bigdata/misrepresentation");
      }
      if (this.headerSelVal == 2) {
        this.$router.push("/bigdata/exceptionFilter");
      }
    },
    // 退出研判
    quitPendingYes() {
      // this.getUserOnLine(1, this.selectGroup.teamId);
      setTimeout(() => {
        window.close();
      }, 500);
    },
    // 确认添加机构
    addOrganizationYes() {
      this.tagsRegion.forEach((y) => {
        y.isChecked = true;
        if (y.subclass) {
          if (y.subclass == "网信部门") {
            this.detailsData.netmailList.push(y);
            this.detailsData.netmailList = this.organizationTypeNoRepeat(
              this.detailsData.netmailList
            );
          }
          if (y.subclass == "网安部门") {
            this.detailsData.networkSecurityList.push(y);
            this.detailsData.networkSecurityList = this.organizationTypeNoRepeat(
              this.detailsData.networkSecurityList
            );
          }
        } else {
          this.detailsData.otherList.push(y);
          this.detailsData.otherList = this.organizationTypeNoRepeat(this.detailsData.otherList);
        }
      });
      this.addOrgdialogVisible = false;
      this.letterChecked(this.detailsData);
      this.altarChecked(this.detailsData);
      this.otherChecked(this.detailsData);
    },
    // 机构去重
    organizationTypeNoRepeat(arr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a.id) && res.set(a.id, 1));
    },
    // 点击添加机构图标
    addOrganization(item) {
      this.detailsData = {};
      this.tagsRegion = [];
      this.addOrgList = [];
      this.addOrgListSelect = [];
      this.addOrgdialogVisible = true;
      this.detailsData = item;
    },
    // 添加机构到tags
    serchOrganization() {
      this.addOrgListSelect.forEach((x) => {
        this.tagsRegion.push(x);
        this.tagsRegion = this.organizationTypeNoRepeat(this.tagsRegion);
      });
      this.addOrgListSelect = [];
    },
    // 删除选中机构
    removeOrganization(index) {
      this.tagsRegion.splice(index, 1);
    },
    // 添加机构
    async remoteMethod(query) {
      if (query !== "") {
        const res = await Bigdata.addExceptionFilterOrg({
          name: query,
        });
        if (res.data.code === 200) {
          this.addOrgList = res.data.data.list;
        } else {
          this.addOrgList = [];
        }
      }
    },
    // 提交数据
    async submitExceptionFilter() {
      this.waitForJudgmentDataLoading = true;
      this.bigDataInfoList.forEach((x) => {
        let arr = [];
        x.netmailList.forEach((y) => {
          if (y.isChecked) {
            arr.push(y);
          }
        });
        x.networkSecurityList.forEach((z) => {
          if (z.isChecked) {
            arr.push(z);
          }
        });
        x.otherList.forEach((m) => {
          if (m.isChecked) {
            arr.push(m);
          }
        });
        x.orgList = arr;
      });
      let data = {
        batchId: this.batchId,
        teamId: 2,
        user: JSON.parse(localStorage.getItem("user")).userId,
        userName: JSON.parse(localStorage.getItem("user")).name,
        data: this.bigDataInfoList,
      };
      const res = await Bigdata.submitExceptionFilter(data);
      if (res.data.code == 200) {
        this.bigDataInfoList = [];
        this.getAbnormalStateNum();
        this.waitForJudgmentDataLoading = false;
        this.$message.success("提交成功！");
      }
    },
    // 清空所有异常状态类型
    emptyAllHarmfulType(index) {
      this.bigDataInfoList[index].messageTypeList = [];
    },
    // 跳转原网址
    jumpUrl(url) {
      window.open("http://" + url, "_blank");
    },
    // 获取待研判数据
    getWaitForJudgmentData() {
      // if (this.waitForJudgmentNum == 0) {
      //   this.$message.error("当前无待处理数据！");
      //   return false;
      // }
      this.getAbnormalState();
    },
    // 获取异常状态数据
    async getAbnormalState() {
      this.waitForJudgmentDataLoading = true;
      const res = await Bigdata.getAbnormalState({
        teamId: 2,
        user: JSON.parse(localStorage.getItem("user")).userId,
        userName: JSON.parse(localStorage.getItem("user")).name,
      });
      if (res.data.code == 200) {
        this.batchId = res.data.data.batchId;
        this.bigDataInfoList = res.data.data.data;
        this.bigDataInfoList.forEach((x) => {
          x.netmailList = [];
          x.networkSecurityList = [];
          x.otherList = [];
          x.isNetmailCheckedAll = true;
          x.isNetworkSecurityCheckedAll = true;
          x.isOtherCheckedAll = true;
          x.isCheckedAll = true;
          x.orgList.forEach((y) => {
            y.isChecked = true;
            if (y.subclass) {
              if (y.subclass == "网信部门") {
                x.netmailList.push(y);
              }
              if (y.subclass == "网安部门") {
                x.networkSecurityList.push(y);
              }
            } else {
              x.otherList.push(y);
            }
          });
          if (x.netmailList.length == 0) {
            x.isNetmailCheckedAll = false;
          }
          if (x.networkSecurityList.length == 0) {
            x.isNetworkSecurityCheckedAll = false;
          }
          if (x.otherList.length == 0) {
            x.isOtherCheckedAll = false;
          }
          x.isNetmailCheckedAll && x.isNetworkSecurityCheckedAll && x.isNetworkSecurityCheckedAll
            ? (x.isCheckedAll = true)
            : (x.isCheckedAll = false);
        });
        this.waitForJudgmentDataLoading = false;
      } else {
        this.bigDataInfoList = [];
        this.waitForJudgmentDataLoading = false;
      }
    },
    //所有全选
    checkAll(item) {
      if (item.isCheckedAll) {
        item.isNetmailCheckedAll = true;
        item.isNetworkSecurityCheckedAll = true;
        item.isOtherCheckedAll = true;
        this.letterCheckAll(item);
        this.altarCheckAll(item);
        this.otherCheckAll(item);
      }
      if (!item.isCheckedAll) {
        item.isNetmailCheckedAll = false;
        item.isNetworkSecurityCheckedAll = false;
        item.isOtherCheckedAll = false;
        this.letterCheckAll(item);
        this.altarCheckAll(item);
        this.otherCheckAll(item);
      }
    },
    //网信类型全选
    letterCheckAll(item) {
      if (item.isNetmailCheckedAll && item.isNetworkSecurityCheckedAll && item.isOtherCheckedAll) {
        item.isCheckedAll = true;
      } else {
        item.isCheckedAll = false;
      }
      if (item.isNetmailCheckedAll) {
        item.netmailList.forEach((x) => {
          x.isChecked = true;
        });
      }
      if (!item.isNetmailCheckedAll) {
        item.netmailList.forEach((x) => {
          x.isChecked = false;
        });
      }
    },
    //网信类型多选
    letterChecked(item) {
      let sum = 0;
      item.netmailList.forEach((x) => {
        if (x.isChecked) {
          sum += 1;
        }
      });
      if (item.netmailList.length > 0) {
        if (item.netmailList.length == sum) {
          item.isNetmailCheckedAll = true;
        } else {
          item.isNetmailCheckedAll = false;
        }
      }
      if (item.isNetmailCheckedAll && item.isNetworkSecurityCheckedAll && item.isOtherCheckedAll) {
        item.isCheckedAll = true;
      } else {
        item.isCheckedAll = false;
      }
    },
    //网安类型全选
    altarCheckAll(item) {
      if (item.isNetmailCheckedAll && item.isNetworkSecurityCheckedAll && item.isOtherCheckedAll) {
        item.isCheckedAll = true;
      } else {
        item.isCheckedAll = false;
      }
      if (item.isNetworkSecurityCheckedAll) {
        item.networkSecurityList.forEach((x) => {
          x.isChecked = true;
        });
      }
      if (!item.isNetworkSecurityCheckedAll) {
        item.networkSecurityList.forEach((x) => {
          x.isChecked = false;
        });
      }
    },
    //网安类型多选
    altarChecked(item) {
      let sum = 0;
      item.networkSecurityList.forEach((x) => {
        if (x.isChecked) {
          sum += 1;
        }
      });
      if (item.networkSecurityList.length > 0) {
        if (item.networkSecurityList.length == sum) {
          item.isNetworkSecurityCheckedAll = true;
        } else {
          item.isNetworkSecurityCheckedAll = false;
        }
      }
      if (item.isNetmailCheckedAll && item.isNetworkSecurityCheckedAll && item.isOtherCheckedAll) {
        item.isCheckedAll = true;
      } else {
        item.isCheckedAll = false;
      }
    },
    //其他类型全选
    otherCheckAll(item) {
      if (item.isNetmailCheckedAll && item.isNetworkSecurityCheckedAll && item.isOtherCheckedAll) {
        item.isCheckedAll = true;
      } else {
        item.isCheckedAll = false;
      }
      if (item.isOtherCheckedAll) {
        item.otherList.forEach((x) => {
          x.isChecked = true;
        });
      }
      if (!item.isOtherCheckedAll) {
        item.otherList.forEach((x) => {
          x.isChecked = false;
        });
      }
    },
    //其他类型多选
    otherChecked(item) {
      let sum = 0;
      item.otherList.forEach((x) => {
        if (x.isChecked) {
          sum += 1;
        }
      });
      if (item.otherList.length > 0) {
        if (item.otherList.length == sum) {
          item.isOtherCheckedAll = true;
        } else {
          item.isOtherCheckedAll = false;
        }
      }
      if (item.isNetmailCheckedAll && item.isNetworkSecurityCheckedAll && item.isOtherCheckedAll) {
        item.isCheckedAll = true;
      } else {
        item.isCheckedAll = false;
      }
    },
    // 获取异常状态数据数量
    async getAbnormalStateNum() {
      const res = await Bigdata.getAbnormalStateNum();
      if (res.data.code == 200) {
        this.waitForJudgmentNum = res.data.data;
      }
    },
    // 获取事件类型
    async getErrorTypeList() {
      const res = await Error.getErrorTypeList();
      if (res.data.code == 200) {
        this.harOptions = res.data.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  border-radius: 0px !important;
}
::v-deep .el-select-dropdown__item.selected {
  color: #ed731f !important;
}
::v-deep .el-select .el-input__inner:focus {
  border-color: #ed731f;
}
::v-deep .el-loading-spinner .path {
  stroke: #ed731f;
}
::v-deep .el-tag {
  border-radius: 0px !important;
}
.tagsRegion {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.tagsRegion-card {
  padding: 6px 16px;
  background: #f5f6fa;
  border-radius: 4px;
  font-size: 14px;
  color: #666666;
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-top: 16px;
}

.tagsRegion-card img {
  width: 16px;
  height: 16px;
  margin-left: 16px;
  cursor: pointer;
}
.noDataDis {
  text-align: center;
  font-size: 40px;
  height: 640px;
  line-height: 500px;
  color: #eeeeee;
}
.delete_h3 {
  font-size: 16px;
  color: #333333;
  padding: 30px 0;
  text-align: center;
  font-weight: 600;
}
.page-header-sel {
  width: 272px;
  border-right: 1px solid #e4e6ec;
  padding-top: 12px;
  padding-bottom: 2px;
  margin-right: 32px;
  ::v-deep {
    .el-input__inner {
      width: 240px;
      height: 36px;
    }

    .el-input__prefix,
    .el-input__suffix {
      height: 36px;
    }
    .el-input.is-focus .el-input__inner {
      caret-color: #f58030;
      border: 1px solid #f58030 !important;
      transition: all 0.3s;
    }
  }
}
.page {
  .page-title {
    height: 37px;
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 37px;
    margin-bottom: 24px;
  }
  .page-header {
    position: relative;
    display: flex;
    justify-content: start;
    width: 100%;
    height: 90px;
    background: #ffffff;
    border-radius: 4px;
    padding: 12px 24px;
    position: sticky;
    top: 80px;
    z-index: 999;
    border-bottom: 1px solid #eeeeee;
    .page-header-btn-list {
      display: flex;
      justify-content: start;
      padding-top: 8px;
      .header-btn {
        width: 136px;
        height: 54px;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        line-height: 22px;
        div {
          height: 54px;
          padding-top: 5px;
          cursor: pointer;
          img {
            margin-right: 6px;
          }
          .small-span {
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
            line-height: 17px;
          }
        }
      }
      .page-header-btn1 {
        background: linear-gradient(270deg, #f89d55 0%, #f4630b 100%);
        margin-right: 32px;
      }
      .page-header-btn2 {
        background: #fff1e8;
        border-radius: 4px;
        display: flex;
        color: #ed731f;
        line-height: 42px;
        text-align: center;
        margin-right: 50px;
        img {
          width: 16px;
          height: 18px;
          vertical-align: middle;
        }
        div {
          width: 100%;
        }
      }
    }
    .page-header-btn-back {
      position: absolute;
      top: 20px;
      right: 24px;
      width: 132px;
      height: 54px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #cccccc;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 54px;
      text-align: center;
      cursor: pointer;
    }
  }
  .big-data-info {
    margin-top: 16px;
    width: 100%;
    .big-data-info-box {
      width: 100%;
      border: 1px solid #e4e6ec;
      margin-bottom: 16px;
    }
    .info-box-header {
      display: flex;
      width: 100%;
      height: 60px;
      background: #f5f6fa;
      padding: 12px 24px;
      position: relative;
      .box-header-sel {
        width: 210px;
        ::v-deep {
          .el-input__inner {
            height: 36px;
          }
          .el-input__prefix,
          .el-input__suffix {
            height: 36px;
          }
          .el-input.is-focus .el-input__inner {
            caret-color: #f58030;
            border: 1px solid #f58030 !important;
            transition: all 0.3s;
          }
        }
      }
      .box-header-btn {
        width: 88px;
        height: 36px;
        background: #f7fffc;
        border: 1px solid #02bc7c;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #02bc7c;
        line-height: 36px;
        text-align: center;
        margin-right: 24px;
        margin-left: 10px;
        cursor: pointer;
      }
      .box-header-h3 {
        width: 1100px;
        height: 36px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .box-header-h3-y {
        color: #ffffff !important;
      }
      .box-header-copy {
        margin-left: 24px;
        line-height: 20px;
        cursor: pointer;
        position: absolute;
        right: 28px;
        img {
          vertical-align: middle;
          margin-right: 6px;
        }
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
      img {
        width: 12px;
        height: 14px;
        margin: 11px 16px 11px 12px;
      }
    }
    .info-box-main {
      width: 100%;
      height: 69px;
      padding: 24px;
      .box-main-author {
        display: flex;
        .main-author-author {
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 40px;
        }
      }
    }
    .info-box-checkbox {
      width: 100%;
      padding: 0 24px 24px 24px;
      display: flex;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      .info-box-checkbox-left {
        width: 100px;
        background: #f5f6fa;
        border: 1px solid #e4e6ec;
        border-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .checkbox-left1 {
          width: 56px;
          height: 24px;
          position: absolute;
          top: 6px;
          margin: auto;
        }
        .checkbox-left2 {
          margin: 24px auto;
        }
        .checkbox-left3 {
          margin: 0 21px;
          cursor: pointer;
        }
      }
      .info-box-checkbox-center {
        width: 60px;
        background: #f5f6fa;
        border-left: 3px solid #ffffff;
        border-right: 3px solid #ffffff;
        border-top: 1px solid #e4e6ec;
        border-bottom: 1px solid #e4e6ec;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 18px;
          height: 18px;
        }
      }
      .info-box-checkbox-right {
        width: calc(100% - 66px);
        background: #f5f6fa;
        display: flex;
        flex-direction: column;
        border: 1px solid #e4e6ec;
        border-left: 0;
        .checkbox-right1 {
          display: flex;
          .checkbox-right1-left {
            width: 170px;
            border-right: 3px solid #ffffff;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: end;
          }
        }
        .checkbox-right2 {
          display: flex;
          background: #ffffff;
          .checkbox-right2-left {
            width: 170px;
            border-right: 3px solid #ffffff;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: end;
          }
        }
        .checkbox-right3 {
          display: flex;
          .checkbox-right3-left {
            width: 170px;
            border-right: 3px solid #ffffff;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: end;
          }
        }
      }
    }
  }
}
.info-box-header-red {
  background: #ea3342 !important;
}
.info-box-main-pink {
  background: #fff4f5;
}
.info-box-copy-color {
  color: #ffffff !important;
}
.checkbox-right-right {
  min-height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  padding-left: 24px;
}
.el-checkbox {
  margin-right: 60px;
}
/* 选中后的字体颜色 */
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f58030 !important;
}
</style>
